.filter-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
}

.filter-input,
.filter-select,
.reset-filters {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    height: 38px; /* Ensure all elements have the same height */
    box-sizing: border-box;
}

.filter-input,
.filter-select {
    flex: 1;
}

.reset-filters {
    background-color: #f44336;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.reset-filters:hover {
    background-color: #d32f2f;
}
