.product-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-card-body {
    margin-top: 10px;
}

.product-actions {
    display: flex;
    gap: 10px;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
}
