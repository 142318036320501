.welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -20px;
}

.welcome-message {
    text-align: left;
    width: 100%;
    max-width: 800px;
    color:#333;
}

.welcome-overview {
    margin-top: -10px;
    text-align: left;
    color: rgb(109, 109, 109);
}

.orders-deadlines-container {
    width: 100%;
    max-width: 800px;
}

.orders-deadlines {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}

.orders-revenue {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap; /* No wrapping by default */
}

.orders-revenue.overflow {
    flex-wrap: wrap; /* Allow wrapping when overflow class is applied */
}

.orders, .revenue {
    text-align: left;
    flex: 1;
    min-width: 100px;
    flex-basis: 50%; /* Basis to handle flex wrapping */
    margin: 10px 0;
}

.orders h3, .revenue h3 {
    font-size: 2rem; 
    margin: 0;
    white-space: nowrap; 
}

.orders p, .revenue p {
    font-size: 1rem;
    color: gray;
    margin: 5px 0;
}

.orders-list ul {
    list-style-type: none;
    padding: 0;
}

.orders-list li {
    margin: 5px 0;
}

.revenue {
    margin-left: -30px;
}

.notifications {
    margin-top: 20px;
    width: 100%;
    text-align: left;
}

.notifications h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.notifications ul {
    list-style-type: none;
    padding: 0;
}

.notifications li {
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 10px;
}

@media (max-width: 600px) {
    .orders-revenue {
        flex-direction: column; 
    }

    .orders, .revenue {
        flex-basis: 100%; /* Full width on small screens */
        text-align: center; /* Center text on small screens */
    }
}
