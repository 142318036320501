.invoices-reports-container {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 200px);
  height: 100vh;
  margin-left: 200px;
  overflow: hidden;
  background-color: #f5f5f5;
}

.filters-section {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filters-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #023020;
}

.search-filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  align-items: center;
}

.search-filters input,
.search-filters select,
.search-filters button {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: 40px;
  box-sizing: border-box;
}

.date-picker-filter {
  position: relative;
}

.date-picker-filter .react-datepicker-wrapper,
.date-picker-filter .react-datepicker__input-container {
  display: block;
  width: 100%;
}

.date-picker-filter .react-datepicker__input-container input {
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: 40px;
  box-sizing: border-box;
}

.date-picker-filter .react-datepicker {
  font-family: inherit;
  border-color: #e0e0e0;
}

.date-picker-filter .react-datepicker-popper {
  z-index: 2;
}

.date-picker-filter .react-datepicker__header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.date-picker-filter .react-datepicker__navigation--previous {
  max-width: 10%;
  border: none;
  left: -1px;
  top: 4px;
}

.date-picker-filter .react-datepicker__navigation--next {
  max-width: 10%;
  border: none;
  right: -1px;
  top: 4px;
}

.search-button {
  background-color: #023020;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.search-button:hover {
  background-color: #034530;
}

.content-section {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.orders-list {
  flex: 0 0 40%;
  padding: 20px;
  overflow-y: auto;
  border-right: 1px solid #e0e0e0;
}

.order-item {
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  background-color: #ffffff;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
}

.order-item:hover {
  background-color: #f0f5f0;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.customer-name {
  font-weight: bold;
  color: #023020;
}

.order-date,
.order-total {
  color: #757575;
}

.document-preview {
  flex: 0 0 60%;
  padding: 20px;
  overflow-y: auto;
}

.invoice-details, .report-preview {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 75%;
  align-content: center;
}

.invoice-details h3, .report-preview h3 {
  margin-bottom: 20px;
  color: #023020;
}

.invoice-details table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 25px;
}

.invoice-details th,
.invoice-details td {
  border: 1px solid #e0e0e0;
  padding: 10px 15px;
  text-align: left;
}

.invoice-details th {
  background-color: #023020;
  color: white;
  font-weight: 600;
  white-space: nowrap;
}

.invoice-details tr:nth-child(even) {
  background-color: #f0f5f0;
}

.export-options {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.export-button {
  padding: 10px 20px;
  background-color: #023020;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
}

.export-button:hover {
  background-color: #034530;
}

.select-order-message {
  color: #757575;
  font-style: italic;
}

@media (max-width: 1200px) {
  .content-section {
    flex-direction: column;
  }

  .orders-list,
  .document-preview {
    flex: none;
    width: 100%;
    height: 50%;
  }

  .search-filters {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .invoices-reports-container {
    margin-left: 0;
    width: 100vw;
  }

  .search-filters {
    grid-template-columns: 1fr;
  }
}