.dashboard-main-container {
    display: flex;
    margin-left: 200px;
    background-color: #f8f9fa;
    min-height: 100vh;
}

.dashboard-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    gap: 20px;
    padding: 20px;
    width: 100%;
}

.card {
    background: rgb(245, 245, 245);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.wide-card {
    grid-column: span 3;
}

.dashboard-welcome {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.dashboard-chart {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.dashboard-shipments {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}

.dashboard-weather {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
}