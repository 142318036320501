:root {
    --primary-color: #2c3e50;
    --secondary-color: #34495e;
    --accent-color: #27ae60;
    --background-color: #f8f9fa;
    --text-color: #333333;
    --border-color: #bdc3c7;
    --card-background: #ffffff;
    --shadow-color: rgba(0, 0, 0, 0.1);
  }
  
  .customer-list-container {
      padding: 30px;
      margin-left: 200px;
      background-color: var(--background-color);
      min-height: 100vh;
      box-shadow: 0 0 10px var(--shadow-color);
  }
  
  .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      border-bottom: 2px solid var(--border-color);
      padding-bottom: 20px;
  }
  
  .header h2 {
      font-size: 28px;
      color: var(--primary-color);
      margin: 0;
  }
  
  .view-buttons {
      display: flex;
      gap: 10px;
  }
  
  .view-buttons button {
      cursor: pointer;
      padding: 8px 12px;
      border: 1px solid var(--border-color);
      border-radius: 4px;
      background-color: var(--card-background);
      font-size: 14px;
      transition: all 0.3s ease;
      color: var(--text-color);
  }
  
  .view-buttons button.active {
      background-color: var(--accent-color);
      color: var(--card-background);
      border-color: var(--accent-color);
  }
  
  .create-button {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      font-size: 14px;
      background-color: var(--accent-color);
      color: var(--card-background);
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  }
  
  .create-button:hover {
      background-color: #219653;
  }
  
  .filters {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 30px;
  }
  
  .customer-cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 40px;
  }
  
  .customer-card {
      background-color: var(--card-background);
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 10px var(--shadow-color);
      transition: box-shadow 0.3s ease;
  }
  
  .customer-card:hover {
      box-shadow: 0 4px 15px var(--shadow-color);
  }
  
  .customer-card h3 {
      margin: 0 0 15px 0;
      font-size: 18px;
      color: var(--primary-color);
  }
  
  .customer-card p {
      margin: 8px 0;
      font-size: 14px;
      color: var(--text-color);
  }
  
  .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 20px;
  }
  
  .button {
      cursor: pointer;
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      transition: background-color 0.3s ease;
  }
  
  .delete-button {
      color: var(--card-background);
      background-color: #e74c3c;
  }
  
  .delete-button:hover {
      background-color: #c0392b;
  }
  
  .edit-button {
      color: var(--card-background);
      background-color: #3498db;
  }
  
  .edit-button:hover {
      background-color: #2980b9;
  }
  
  .pagination {
      display: flex;
      justify-content: center;
      margin: 30px 0;
  }
  
  .page-number {
      margin: 0 5px;
      padding: 8px 12px;
      border: 1px solid var(--border-color);
      cursor: pointer;
      background-color: var(--card-background);
      border-radius: 4px;
      font-size: 14px;
      transition: all 0.3s ease;
      color: var(--text-color);
  }
  
  .page-number.active {
      background-color: var(--accent-color);
      color: var(--card-background);
      border-color: var(--accent-color);
  }
  
  .page-number:hover {
      background-color: var(--border-color);
  }