.order-card {
    background: rgb(255, 255, 255);
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-card-body {
    margin-top: 10px;
}

.order-actions {
    display: flex;
    gap: 10px;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
}

.clear-weather {
    color: #FFD700;
}

.partly-cloudy-weather {
    color: #B0C4DE;
}

.rainy-weather {
    color: #00CED1;
}

/* New styles for status dropdown */
.status-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.status-container label {
    margin-right: 5px;
}

.status-container select {
    margin-left: 5px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}
