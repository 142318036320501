.spreadsheet-view table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.spreadsheet-view th, .spreadsheet-view td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.spreadsheet-view th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
}

.icon-button:focus {
    outline: none;
}

.spreadsheet-table {
    background-color: white;
}

/* Add this CSS rule to make the dropdown box shorter in height */
.spreadsheet-view select.form-control {
    height: 26px; /* Adjust the height as needed */
    padding: 2px 3px; /* Adjust the padding as needed */
    font-size: 13px; /* Adjust the font size as needed */
}
