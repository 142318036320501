.overview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -20px;
}

.overview-title {
    align-self: flex-start;
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
}

.time-span-selector {
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
    z-index: 1;
}

.time-span-select {
    appearance: none;
    background-color: #f7f7f8;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 16px;
    color: #333;
    outline: none;
    cursor: pointer;
    width: 150px;
    text-align: left;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 15l-5-5h10z' fill='%23333'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 12px 12px;
}

.time-span-select:focus {
    border-color: #a3a3a3;
}

.overview {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.overview-chart {
    width: 100% !important;
    max-width: 200px !important;
    position: relative;
}

.chart-container {
    width: 100% !important;
    height: 200px !important; 
    margin-top: 20px;
}

.chart-legend {
    margin-top: 20px;
}

.legend-item {
    display: flex;
    align-items: center;
    margin: 0 10px;
}

.legend-color-box {
    width: 15px;
    height: 15px;
    margin-right: 8px;
}
