.weather-card {
    background: linear-gradient(to right, #4a90e2, #63b8ff);
    color: white;
    padding: 1.5rem;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    width: 100%;
    box-sizing: border-box;
}
  
  .weather-card h2 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  
  .forecast-container {
    display: flex;
    justify-content: space-between;
  }
  
  .forecast-day {
    flex: 1;
    text-align: center;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    margin: 0 0.25rem;
  }
  
  .forecast-day h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
  }
  
  .weather-icon {
    width: 40px;
    height: 40px;
  }
  
  .forecast-temp {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0.25rem 0;
  }
  
  .forecast-description {
    font-size: 0.8rem;
    text-transform: capitalize;
    margin-bottom: 0.25rem;
  }
  
  .forecast-details {
    font-size: 0.7rem;
  }
  
  .forecast-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.1rem;
  }
  
  .loading, .error {
    text-align: center;
    font-size: 1.2rem;
    padding: 2rem;
  }
  
  .error {
    color: #ff6b6b;
  }
  
  @media (max-width: 768px) {
    .weather-card {
      grid-column: 1 / -1; /* Make it full width on smaller screens */
    }
  
    .forecast-container {
      flex-direction: column;
    }
  
    .forecast-day {
      margin: 0.5rem 0;
    }
  }