.user-settings-main-container {
    width: calc(100% - 220px); 
    height: fit-content;
    margin-left: 200px;
    padding: 20px;
    background-color: #efeed8;
    min-height: 100vh;
}

.userSettings-column-container {
    display: flex;
}

.userSettings-content {
    width: 100%;
}

