.farm-settings-container {
    padding: 30px;
    margin-left: 200px; /* Account for sidebar width */
    background-color: #f8f9fa;
    min-height: calc(100vh - 60px);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e9ecef;
}

.header h1 {
    font-size: 24px;
    color: #333;
    margin: 0;
    font-weight: 600;
}

.save-button {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.save-button:hover {
    background-color: #218838;
}

.save-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.section {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.section h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
}

.input-group {
    margin-bottom: 15px;
    margin-right: 10px;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #555;
}

input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
}

.main-location {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.main-location h3 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
}

.location-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 4px;
    margin-bottom: 10px;
}

.edit-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 12px;
    font-weight: 600;
}

.edit-button:hover {
    background-color: #0056b3;
}

.add-location-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    align-items: end;
}

.add-button {
    padding: 8px 16px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;
    margin-bottom: 16px;
    width: 15%;
    grid-column: span 2;
}

.add-button:hover {
    background-color: #218838;
}

@media (max-width: 768px) {
    .farm-settings-container {
        margin-left: 0;
        padding: 20px;
    }

    .add-location-form {
        grid-template-columns: 1fr;
    }

    .add-location-form button {
        grid-column: auto;
    }
}