.spreadsheet-view {
    overflow-x: auto;
    margin-top: 20px;
}

.filter-controls {
    margin-bottom: 10px;
}

.spreadsheet-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px; 
}

.spreadsheet-table th, .spreadsheet-table td {
    border: 1px solid #ddd;
    padding: 4px; 
    text-align: left;
    white-space: nowrap; 
}

.spreadsheet-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 5px; 
    font-size: 14px; 
}

.icon-button:focus {
    outline: none;
}

.actions-cell {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
}
