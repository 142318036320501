.shipments-container {
    margin-top: -20px;
}

.shipments-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.shipments-list {
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    max-height: 400px; 
    overflow: hidden;
    position: relative; 
}

.shipment-header, .shipment-item {
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    gap: 10px;
    align-items: center;
    padding: 15px;
}

.shipment-header {
    background-color: #f9f9f9;
    font-weight: bold;
    border-bottom: 1px solid #e0e0e0;
}

.shipment-tracking-header, .shipment-status-header, .shipment-date-header,
.shipment-tracking, .shipment-status, .shipment-date {
    font-size: 12px;
    color: #333;
}

.shipment-tracking-header, .shipment-tracking {
    text-align: left;
}

.shipment-status-header, .shipment-status {
    text-align: left;
}

.shipment-date-header, .shipment-date {
    text-align: right;
}

.shipment-items {
    overflow-y: auto;
    max-height: 360px; 
}

.shipment-item {
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0; 
}

.shipment-item:last-child {
    border-bottom: none; 
}

.shipment-status {
    display: inline-block;
    padding: 4px 8px; 
    border-radius: 20px; 
    font-size: 12px; 
    color: white;
    font-weight: bold;
    width: fit-content;
    min-width: 100px;
    text-align: center;
    margin-left: -36px;
}

.shipment-status.awaiting-harvest { background-color: #A3BE8C; }
.shipment-status.harvested { background-color: #88C0D0; }
.shipment-status.processing { background-color: #EBCB8B; }
.shipment-status.ready-for-pickup { background-color: #D08770; }
.shipment-status.in-transit { background-color: #5E81AC; }
.shipment-status.delivered { background-color: #B48EAD; }
.shipment-status.completed { background-color: #4C566A; }
.shipment-status.on-hold { background-color: #BF616A; }
.shipment-status.unknown { background-color: #4C566A; }

.shipments-list::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(222, 222, 222));
    pointer-events: none; 
    transition: opacity 0.3s; 
}

.shipments-list.hide-gradient::after {
    opacity: 0; 
}

.no-shipments {
    text-align: center;
}

