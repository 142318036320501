/* Modal Base Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 30px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.modal-title {
    font-size: 24px;
    font-weight: 600;
    color: #2c3e50;
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #7f8c8d;
    cursor: pointer;
    transition: color 0.3s ease;
}

.close-button:hover {
    color: #34495e;
}

.modal-body {
    margin-bottom: 24px;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

/* Form Styles */
.form-group {
    margin-bottom: 20px;
}

.form-label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #34495e;
    margin-bottom: 8px;
}

.form-control {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    transition: border-color 0.3s ease;
}

.form-control:focus {
    border-color: #3498db;
    outline: none;
}

/* Button Styles */
.btn {
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-primary {
    background-color: #3498db;
    color: #ffffff;
}

.btn-primary:hover {
    background-color: #2980b9;
}

.btn-secondary {
    background-color: #ecf0f1;
    color: #2c3e50;
}

.btn-secondary:hover {
    background-color: #bdc3c7;
}

/* Specific Modal Styles */
.create-customer-form,
.create-order-form,
.create-product-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-group-compact {
    display: flex;
    gap: 20px;
}

.form-group-compact .form-group {
    flex: 1;
}

.autocomplete-address-input {
    width: 100%;
}

.success-message {
    color: #27ae60;
    margin-top: 12px;
    font-weight: 500;
}

/* Responsive Design */
@media (max-width: 768px) {
    .modal-container {
        width: 95%;
        padding: 20px;
    }

    .form-group-compact {
        flex-direction: column;
        gap: 0;
    }
}