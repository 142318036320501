.order-list-container {
    padding: 30px;
    margin-left: 200px;
    background-color: #f8f9fa;
    min-height: 100vh;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    border-bottom: 2px solid #e9ecef;
    padding-bottom: 20px;
}

.header h2 {
    font-size: 28px;
    color: #343a40;
    margin: 0;
}

.header-controls {
    display: flex;
    align-items: center;
    gap: 20px;
}

.view-buttons-container {
    display: flex;
    gap: 10px;
}

.view-buttons button {
    cursor: pointer;
    padding: 8px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    transition: all 0.3s ease;
}

.view-buttons button.active {
    background-color: #007c1f;
    color: white;
    border-color: #007c1f;
}

.create-button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    font-size: 14px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.create-button:hover {
    background-color: #218838;
}

.pagination-controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

.pagination-controls label {
    font-size: 14px;
    color: #6c757d;
}

.pagination-controls select {
    padding: 6px 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    border-bottom: 1px solid #dee2e6;
}

.tab {
    padding: 12px 24px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 16px;
    color: #495057;
    transition: all 0.3s ease;
}

.tab.active {
    color: #218838;
    border-bottom: 2px solid #218838;
}

.filters {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 30px;
}

.order-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 40px;
}

.order-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.order-card:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.order-card h3 {
    margin: 0 0 15px 0;
    font-size: 18px;
    color: #343a40;
}

.order-card p {
    margin: 8px 0;
    font-size: 14px;
    color: #6c757d;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.button {
    cursor: pointer;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.delete-button {
    color: white;
    background-color: #dc3545;
}

.delete-button:hover {
    background-color: #c82333;
}

.edit-button {
    color: white;
    background-color: #17a2b8;
}

.edit-button:hover {
    background-color: #138496;
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 30px 0;
}

.page-number {
    margin: 0 5px;
    padding: 8px 12px;
    border: 1px solid #dee2e6;
    cursor: pointer;
    background-color: #fff;
    border-radius: 4px;
    font-size: 14px;
    transition: all 0.3s ease;
}

.page-number.active {
    background-color: #4caf50;
    color: white;
    border-color: #4caf50;
}

.page-number:hover {
    background-color: #3e8f41;
}