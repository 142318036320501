.sidebar {
    width: 200px;
    background-color: #023020; /* Keep the same dark green */
    display: flex;
    flex-direction: column;
    color: white;
    padding: 20px;
    box-sizing: border-box; 
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    overflow: auto; 
}

.sidebar-logo {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px; 
    margin-left: -25px;
}

.sidebar-nav {
    flex-grow: 1;
    overflow-y: auto; /* Allow vertical scrolling for nav items */
}

.sidebar-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    padding: 10px 0;
    font-size: 1.2em;
    cursor: pointer;
    position: relative;
}

.nav-item:hover {
    background-color: #356b05;
    border-radius: 5px;
    padding-left: 10px;
}

.nav-item.active::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 50%;
    background-color: white; 
}

.nav-icon {
    margin-right: 8px;
}

.nav-text {
    font-size: 1em;
}

.logo-image {
    width: 180px; 
    height: auto;
    display: block;
    align-items: center;
}

.sidebar-logout {
    padding-top: 10px;
    border-top: 1px solid #ffffff33; 
    margin-top: auto;
}

.logout-button {
    background: none;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.2em;
    cursor: pointer;
    width: 100%;
    padding: 10px 0; 
}

.logout-button:hover {
    background-color: #356b05;
    border-radius: 5px;
    padding-left: 10px;
}
