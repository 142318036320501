.spreadsheet-view {
    overflow-x: auto;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}

.spreadsheet-table {
    width: 100%; 
    border-collapse: collapse;
    margin-top: 20px;
    background-color: white;
}

.spreadsheet-table th, .spreadsheet-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.spreadsheet-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    font-size: 16px;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
}

.icon-button:focus {
    outline: none;
}
